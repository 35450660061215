<template>
    <defaultSec :title="'素材管理'" :isDisableScroll="true" :isFlexSection="true">
        <div class="material-list">
            <el-scrollbar class="route-left">
                <el-tabs v-model="activeTab">
                    <el-tab-pane label="素材状态" name="1">
                        <p v-for="item in tripState" class="route-left-item"
                           :class="formParam.status==item.value?'route-left-item-on':''" @click="selectTripState(item)">
                            <span :style="{color: item.point}">● </span>{{item.name}}
                        </p>
                    </el-tab-pane>
                    <el-tab-pane label="素材目录" name="2">
                        <div style="display: flex;align-items: center">
                            <el-input size="mini" v-model="filterGroupName" placeholder="筛选目录">
                              <i slot="prefix" class="el-input__icon el-icon-search"></i>
                            </el-input>
                            <el-tooltip content="新增素材目录" placement="top" effect="light">
                                <el-button size="small" @click="addEditGroupItem(0)"
                                           style="color:#e85656;margin-left:10px;font-size: 18px"
                                           icon="el-icon-circle-plus-outline" type="text"></el-button>
                            </el-tooltip>

                        </div>
                        <div class="custom-tree-node" style="padding: 0 2px 5px"
                             :class="formParam.groupId==''?'is-Current-node':''">
                            <div @click="tripGroupSel({},{id:''})">
                                <i class="el-icon-folder-opened" style="margin-right: 5px"></i>
                                <p class="tree-name">所有</p>
                            </div>
                        </div>
                        <el-tree
                                ref="tree"
                                :data="treeData"
                                :filter-node-method="filterGroupNode">
                            <div class="custom-tree-node" :class="formParam.groupId==data.id?'is-Current-node':''"
                                 slot-scope="{ node, data }">
                                <div @click="tripGroupSel(node,data)">
                                    <i class="el-icon-folder-opened" :class="data.type==1?'icon-green':'icon-red'"
                                       style="margin-right: 5px"></i>
                                    <el-tooltip class="item" effect="dark"
                                                :content="data.groupName+'('+data.quantity+')'" placement="right-start">
                                        <p class="tree-name">{{ data.groupName }}({{data.quantity}})</p>
                                    </el-tooltip>
                                </div>
                                <el-dropdown placement="bottom-start" class="drop-down"
                                             @command="(command)=>handleGroupCommand(command,data)" trigger="click">
                                    <span class="el-dropdown-link">
                                      <i class="icon-edit el-icon-more"></i>
                                    </span>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item command="rule">规则管理</el-dropdown-item>
                                        <el-dropdown-item command="edit">修改目录</el-dropdown-item>
                                        <el-dropdown-item command="del">删除目录</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>
                        </el-tree>
                    </el-tab-pane>
                </el-tabs>
            </el-scrollbar>
            <el-scrollbar class="route-right">
                <div class="search-content" style="display: flex;justify-content: space-between;align-items: center">
                    <el-form :model="formParam" :inline="true" size="mini" class="demo-form-inline">
                        <el-form-item label="类型">
                            <el-select v-model="formParam.materialType" placeholder="请选择" @change="initList">
                                <el-option label="文本" value="text"></el-option>
                                <el-option label="(临时)图片/视频/语音/文件" value="media"></el-option>
                                <el-option label="图文" value="link"></el-option>
                                <el-option label="小程序" value="miniprogram"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="素材编码">
                            <el-input type="text" v-model="formParam.materialCode" placeholder="请输入素材编码"></el-input>
                        </el-form-item>
                        <el-form-item label="文本内容">
                            <el-input type="text" v-model="formParam.pushText" placeholder="请输入文案内容"></el-input>
                        </el-form-item>
                        <el-form-item label="状态">
                            <el-select v-model="formParam.status" placeholder="请选择">
                                <el-option label="全部" :value="-1"></el-option>
                                <el-option label="已发布" :value="1"></el-option>
                                <el-option label="待发布" :value="2"></el-option>
                                <el-option label="驳回" :value="3"></el-option>
                                <el-option label="不可用" :value="0"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="SOP执行日期">
                            <el-date-picker
                                    v-model="formParam.date"
                                    type="daterange"
                                    range-separator="至"
                                    format="yyyy-MM-dd"
                                    value-format="yyyy-MM-dd"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                            >
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="initList">查询</el-button>
                        </el-form-item>
                    </el-form>
                    <el-button type="danger" size="mini" @click="showDetail()">+新建素材</el-button>
                </div>
                <tablePagination :total="0" :pageSize="formParam.pageSize" :hidePage="true"
                                 @handlePage="handlePage">
                    <el-table class="material-table" :data="tableList" :height="tableHeight" border size="mini"
                              v-loading="loading">
                        <el-table-column fixed type="index" label="序号"></el-table-column>
                        <el-table-column fixed prop="materialCode" label="素材编码">
                            <template slot-scope="scope">
                                <!--                                <div style="font-size: 14px;font-weight: 600">{{scope.row.materialCode}}</div>-->
                                <span style="font-size: 14px;font-weight: 600;margin-right: 10px">{{scope.row.materialCode}}</span>
                                <el-popover
                                        popper-class="popper-content"
                                        placement="bottom-start"
                                        width="200"
                                        size="mini"
                                        trigger="hover">
                                    <h4 style="margin: 2px 0 20px">修改素材编码</h4>
                                    <el-input size="mini" type="text" v-model="scope.row.materialCode"
                                              placeholder="请输入素材编码"></el-input>
                                    <el-button size="mini" style="float: right;margin-top: 10px" type="danger"
                                               @click="saveMaterialCode(scope.row)">保存



                                    </el-button>
                                    <el-button slot="reference" size="mini" type="text" icon="el-icon-edit"></el-button>
                                </el-popover>
                            </template>
                        </el-table-column>
                        <!--                        <el-table-column prop="parentMaterialCode" label="前置素材">-->
                        <!--                            <template slot-scope="scope">-->
                        <!--                                <div style="font-size: 14px;font-weight: 600">{{scope.row.parentMaterialCode}}</div>-->
                        <!--                            </template>-->
                        <!--                        </el-table-column>-->
                        <!--                        &lt;!&ndash;                <el-table-column prop="createUserName" label="创建人"></el-table-column>&ndash;&gt;-->
                        <!--                        <el-table-column prop="materialType" label="素材类型">-->
                        <!--                            <template slot-scope="scope">-->
                        <!--                                <div style="font-size: 10px;font-style: italic">-->
                        <!--                                    <i style="color: #55a532" class="el-icon-s-order"></i>{{materialTypeList[scope.row.materialType]}}-->
                        <!--                                </div>-->
                        <!--                            </template>-->
                        <!--                        </el-table-column>-->
                        <!--                        <el-table-column prop="materialClass" width="90px" label="素材分类">-->
                        <!--                            <template slot-scope="scope">-->
                        <!--                                <el-tag size="mini" type="success" v-if="scope.row.materialClass==1">用户素材</el-tag>-->
                        <!--                                <el-tag size="mini" type="warning" v-if="scope.row.materialClass==2">内部素材</el-tag>-->
                        <!--                            </template>-->
                        <!--                        </el-table-column>-->

                        <el-table-column label="推送文本" width="280px" v-if="formParam.materialType == 'text'">
                            <template slot-scope="scope">
                                <div v-if="scope.row.pushText && formParam.materialType == 'text'" class="copy-text" v-html="scope.row.pushText"></div>
                            </template>
                        </el-table-column>
                        <el-table-column label="非标回复文本" width="280px" v-if="formParam.materialType == 'text'">
                            <template slot-scope="scope">
                                <div v-if="scope.row.keyword && formParam.materialType == 'text'" class="copy-text" v-html="scope.row.keyword"></div>
                            </template>
                        </el-table-column>
                        <el-table-column label="预览" width="280px" v-if="formParam.materialType == 'media'">
                            <template slot-scope="scope">
                                <div class="chat-content chat-image">
                                    <!--  v-if="formParam.materialType == 'media'" TODO 图片预览 v-if="scope.row.mediaType='image'" -->
                                    <el-image v-if="scope.row.mediaType='image'" :src="scope.row.ossUrl" :preview-src-list="[scope.row.ossUrl]"></el-image>
                                    <!-- TODO 视频预览 v-if="scope.row.mediaType='video'" -->
                                    <p v-if="scope.row.mediaType!='image'">视频语音文件暂不支持预览</p>
                                    <!-- TODO 语音预览 v-if="scope.row.mediaType='voice'" -->

                                    <!-- TODO 文件预览 v-if="scope.row.mediaType='file'" -->

                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="预览" width="240" v-if="formParam.materialType == 'miniprogram'">
                            <template slot-scope="scope">
                                <miniProgram :miniTitle="scope.row.title" :miniImg="scope.row.miniImgUrl">
                                    <el-button v-show="false" plain size="mini" type="success">发送</el-button>
                                </miniProgram>
                            </template>
                        </el-table-column>
                        <el-table-column label="触发对象" width="250px">
                            <template slot-scope="scope">
                                <div class="copy-text">
                                    <div>
                                        <i style="color: #2dacd1" class="el-icon-s-order"></i>旅程:




                                        <el-link v-if="scope.row.routeList!=null" :href="goRoute(route.id,route.name)"
                                                 v-for="route in scope.row.routeList" target="_blank">
                                            {{route.name}}




                                        </el-link>
                                    </div>
                                    <div>
                                        <i style="color: #55a532" class="el-icon-s-promotion"></i>群组:




                                        <!--<el-link v-if="scope.row.senceList!=null" :href="goSence(sence.id)" v-for="sence in scope.row.senceList"  target="_blank">-->
                                        <!--{{sence.name}}-->
                                        <!--</el-link>-->
                                        <span v-if="scope.row.senceList!=null" v-for="sence in scope.row.senceList">
                                            <el-link v-if="sence.id!=null" :href="goSence(sence.id)" target="_blank">
                                            {{sence.name}}
                                            </el-link>

                                        </span>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>

                        <!--                        <el-table-column prop="createTime" label="创建日期"></el-table-column>-->


                        <!--                      <el-table-column label="流转" width="60px">-->
                        <!--                        <template slot-scope="scope">-->
                        <!--                          <el-tag size="mini" type="primary" v-if="scope.row.isTransfer==1">是</el-tag>-->
                        <!--                          <el-tag size="mini" type="danger"  v-if="scope.row.isTransfer==0">否</el-tag>-->
                        <!--                        </template>-->
                        <!--                      </el-table-column>-->
                        <el-table-column prop="firstSnedTime" sortable label="首发日期" width="100px"></el-table-column>
                        <el-table-column prop="shouldSendCount" sortable label="应发" align="right"></el-table-column>
                        <el-table-column prop="sendCount" sortable label="实发" align="right"></el-table-column>
                        <el-table-column prop="replyRate" :sortable="true" :sort-method="sortReplyPercent" align="right"
                                         label="回复率" width="90px">
                            <template slot-scope="scope">
                                <el-tag size="mini" type="primary" v-if="scope.row.replyRateValue>=0.2">
                                    {{scope.row.replyRate}}



                                </el-tag>
                                <el-tag size="mini" type="warning"
                                        v-if="scope.row.replyRateValue<0.2&&scope.row.replyRateValue>=0.15">
                                    {{scope.row.replyRate}}



                                </el-tag>
                                <el-tag size="mini" type="danger"
                                        v-if="scope.row.replyRateValue<0.15&&scope.row.replyRateValue>0">
                                    {{scope.row.replyRate}}



                                </el-tag>
                                <el-tag size="mini" type="info"
                                        v-if="scope.row.replyRateValue==null||scope.row.replyRateValue==0">
                                    {{scope.row.replyRate}}



                                </el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="sevenRate" :sortable="true" :sort-method="sortConvertPercent"
                                         align="right" label="转化率" width="90px">
                            <template slot-scope="scope">
                                <el-tag size="mini" type="primary" v-if="scope.row.sevenRateValue>=0.05">
                                    {{scope.row.sevenRate}}



                                </el-tag>
                                <el-tag size="mini" type="warning"
                                        v-if="scope.row.sevenRateValue<0.05&&scope.row.sevenRateValue>=0.03">
                                    {{scope.row.sevenRate}}



                                </el-tag>
                                <el-tag size="mini" type="danger"
                                        v-if="scope.row.sevenRateValue<0.03&&scope.row.sevenRateValue>0">
                                    {{scope.row.sevenRate}}



                                </el-tag>
                                <el-tag size="mini" type="info"
                                        v-if="scope.row.sevenRateValue==null||scope.row.sevenRateValue==0">
                                    {{scope.row.sevenRate}}



                                </el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="maxReplyRateDesc" sortable label="最高回复" width="100px"></el-table-column>
                        <el-table-column prop="maxConvertRate" sortable label="最高转化" width="100px"></el-table-column>
                        <!--<el-table-column prop="pushType" label="推送类型">-->
                        <!--<template slot-scope="scope">-->

                        <!--</template>-->
                        <!--</el-table-column>-->
                        <el-table-column label="状态/类型" width="100px">
                            <template slot-scope="scope">
                                <div>
                                    <div>
                                        <el-tag size="mini" type="primary" v-if="scope.row.status==1">已发布</el-tag>
                                        <el-tag size="mini" type="danger" v-if="scope.row.status==0">已禁用</el-tag>
                                        <el-tag size="mini" type="danger" v-if="scope.row.status==2">待发布</el-tag>
                                        <el-tag size="mini" type="danger"
                                                @click="showCheckContent(scope.row.checkContent)"
                                                v-if="scope.row.status==3">驳回
                                        </el-tag>
                                    </div>
                                    <div>
                                        <el-tag size="mini" type="primary" v-if="scope.row.pushType==1">企微推送</el-tag>
                                        <el-tag size="mini" type="success" v-if="scope.row.pushType==2">自动推送</el-tag>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column fixed="right" label="操作" width="100px">
                            <template slot-scope="scope">

                                <el-button type="text" plain size="mini" @click="showDetail(scope.row)">查看</el-button>
                                <el-button type="text" plain size="mini" @click="showTrace(scope.row)">追踪</el-button>
                                <el-button type="text" plain size="mini" @click="goGetNum(scope.row.senceList)">预估



                                </el-button>

                                <el-button type="text" plain size="mini" @click="showExample(scope.row)">案例</el-button>
                                <el-button type="text" plain size="mini" v-if="scope.row.status==0"
                                           @click="changeStatus(scope.row)">启用



                                </el-button>
                                <el-button type="text" plain size="mini" v-if="scope.row.status==1"
                                           @click="changeStatus(scope.row)">禁用



                                </el-button>
                                <el-button type="text" plain size="mini" @click="deleteMaterial(scope.row)">删除



                                </el-button>

                                <!--                                <el-dropdown style="margin-left: 16px" placement="bottom-start">-->
                                <!--                                    <el-button type="text" class="el-dropdown-link">-->
                                <!--                                        •••-->
                                <!--                                    </el-button>-->
                                <!--                                    <el-dropdown-menu slot="dropdown">-->
                                <!--                                      <el-dropdown-item type="warning" plain size="mini" @click.native="showExample(scope.row)">成功案例</el-dropdown-item>-->

                                <!--                                      <el-dropdown-item v-if="scope.row.status==0"  @click.native="changeStatus(scope.row)">启用</el-dropdown-item>-->
                                <!--                                        <el-dropdown-item v-if="scope.row.status==1" type="warning" plain size="mini" @click.native="changeStatus(scope.row)">禁用</el-dropdown-item>-->
                                <!--                                        <el-dropdown-item type="warning" plain size="mini" @click.native="deleteMaterial(scope.row)">删除</el-dropdown-item>-->

                                <!--                                    </el-dropdown-menu>-->
                                <!--                                </el-dropdown>-->

                            </template>
                        </el-table-column>
                    </el-table>
                </tablePagination>

            </el-scrollbar>
        </div>

        <el-dialog
                title="群组人数预估"
                :visible.sync="dialogSenceVisible"
                width="50%">
            <el-form :model="senceGroupNumForm" v-loading="loadingNum" label-width="120px" size="small ">
                <el-form-item label="群组">
                    <el-select v-model="senceGroupNumForm.groupId" placeholder="">
                        <el-option
                                v-for="item in senceGroupNumForm.sceneList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="请选择预估日期">
                    <el-date-picker v-model="yuGuDate" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"
                                    :picker-options="pickerOptions"></el-date-picker>
                </el-form-item>
                <el-form-item label="预估人数" v-show="showNum">
                    <el-input v-model="senceGroupNumForm.num" placeholder="预估人数" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size="mini" @click="getGroupNum">确 定</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

        <el-dialog
                title="新建/编辑素材"
                :visible.sync="dialogVisible"
                width="70%">
            <el-form :model="form" label-width="120px" size="small ">

                <el-form-item label="素材编码">
                    <el-input v-model="form.materialCode" placeholder="输入素材编码" :disabled="form.id>0"></el-input>
                </el-form-item>

                <el-form-item label="素材类型">
                    <el-select v-model="form.materialType" placeholder="请选择素材类型">
                        <el-option label="文本" value="text"></el-option>
                        <el-option label="图片" value="image" disabled></el-option>
                        <el-option label="小程序" value="mini" disabled></el-option>
                        <el-option label="图文" value="news" disabled></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="素材分类">
                    <el-select v-model="form.materialClass" placeholder="请选择素材分类">
                        <el-option label="用户素材" :value="1"></el-option>
                        <el-option label="内部素材" :value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="前置素材">
                    <el-select v-model="form.parentId" placeholder="请选择前置素材" clearable>
                        <el-option
                                v-for="item in materialOption"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="推送文本">
                    <div style="display: flex">
                        <el-input type="textarea" :rows="6" v-model="form.pushText" placeholder="输入推送文本"
                                  @blur="blurEvent"></el-input>
                        <div style="margin-left: 10px">
                            <p style="margin: 0">可选择变量作为参数:</p>
                            <el-tag style="margin: 0 10px 10px 0" v-for="item in tabsTemplateList" size="mini"
                                    type="success" @click="insertContentHandle(item.template,0)">{{item.name}}



                            </el-tag>
                            <!--            <el-tag type="success" @click="insertContentHandle('${materialText.url}')">URL参数</el-tag>-->
                        </div>
                    </div>

                </el-form-item>
                <el-form-item label="非标回复文本">

                    <div style="display: flex">
                        <el-input type="textarea"
                                  :rows="6" v-model="form.keyword" placeholder="非标回复文本" @blur="blurEvent1"></el-input>
                        <div style="margin-left: 10px">
                            <p style="margin: 0">可选择变量作为参数:</p>
                            <el-tag style="margin: 0 10px 10px 0" v-for="item in tabsTemplateList" size="mini"
                                    type="success" @click="insertContentHandle(item.template,1)">{{item.name}}



                            </el-tag>
                            <!--            <el-tag type="success" @click="insertContentHandle('${materialText.url}')">URL参数</el-tag>-->
                        </div>
                    </div>
                </el-form-item>

                <!--          <el-form-item label="URL">-->
                <!--            <el-input v-model="form.url" placeholder="url"  @input="urlChange()" ></el-input>-->
                <!--            推送文本中的链接-->
                <!--          </el-form-item>-->
                <el-form-item label="埋点Id/chan_id">
                    <el-input v-model="form.chanId" placeholder="埋点Id" readonly="readonly"></el-input>
                    推送文本中的链接的chan_id,系统自动获取

                </el-form-item>
                <el-form-item label="加入目录">
                    <el-select v-model="form.groupId">
                        <el-option v-for="item in treeData" :label="item.groupName" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="排序">
                    <el-input-number v-model="form.seq" :min="0"></el-input-number>
                </el-form-item>
                <el-form-item label="非工作日是否流转">
                    <el-select style="width: 50%" size="small" v-model="form.isTransfer" placeholder="请选择">
                        <el-option :value="1" label="是"></el-option>
                        <el-option :value="0" label="否"></el-option>
                    </el-select>
                    <span><i class="el-icon-warning"
                             style="margin-left:10px;color: #E6A23C"></i> 标示非工作时间文案是否流转到下个工作日</span>

                </el-form-item>
                <el-form-item label="推送类型">
                    <el-select style="width: 50%" size="small" v-model="form.pushType" placeholder="请选择">
                        <el-option v-for="item in pushTypeList"
                                   :key="item.id"
                                   :label="item.name"
                                   :value="item.id">
                        </el-option>
                    </el-select>
                    <span><i class="el-icon-warning" style="margin-left:10px;color: #E6A23C"></i>自动推送将由企微确认,企微确认后推送,手动推送 由企微通过打开聊天窗口发送 </span>

                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="saveMaterial">确 定</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog
                title="新建/编辑目录"
                :visible.sync="dialogGroupVisible"
                width="60%">
            <el-form label-width="130px">
                <el-form-item label="目录名称">
                    <el-input clearable size="small" placeholder="请输入名称" v-model="selGroup.groupName"></el-input>
                </el-form-item>
                <el-form-item label="目录属性">
                    <el-select style="width: 100%" size="small" v-model="selGroup.type" placeholder="请选择属性"
                               disabled="disabled">
                        <el-option :value="1" label="公共目录"></el-option>
                        <el-option :value="2" label="私有目录"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="排序">
                    <el-input-number v-model="selGroup.seq" :min="0"></el-input-number>
                </el-form-item>
                <el-form-item label="非工作日是否流转">
                    <el-select style="width: 50%" size="small" v-model="selGroup.isTransfer" placeholder="请选择">
                        <el-option :value="1" label="是"></el-option>
                        <el-option :value="0" label="否"></el-option>
                    </el-select>
                    <span><i class="el-icon-warning" style="margin-left:10px;color: #E6A23C"></i>标示非工作日文案是否流转</span>

                </el-form-item>
                <el-form-item label="工作日是否流转">
                    <el-select style="width: 50%" size="small" v-model="selGroup.isWorkTransfer" placeholder="请选择">
                        <el-option :value="1" label="是"></el-option>
                        <el-option :value="0" label="否"></el-option>
                    </el-select>
                    <span><i class="el-icon-warning" style="margin-left:10px;color: #E6A23C"></i>标示工作时间未完成的文案是否流转</span>
                </el-form-item>
            </el-form>

            <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogGroupVisible = false">取 消</el-button>
                    <el-button type="primary" @click="submitGroupItem">确 定</el-button>
                </span>
        </el-dialog>
        <el-dialog title="目录规则" :visible.sync="dialogGroupRuleVisible" width="60%">
            <div style="width: 100%;">
                目录：{{selGroup.groupName}}

            </div>
            <el-table
                    size="mini"
                    :data="groupRuleList"
                    border
                    style="width: 100%;text-align: center">
                <el-table-column type="index" label="序号"></el-table-column>
                <el-table-column
                        label="规则有效范围">
                    <template slot-scope="scope">
                        <el-select v-model="scope.row.repeatStatus" placeholder="请选择范围" disabled="disabled">
                            <el-option label="全局" :value="1"></el-option>
                            <el-option label="组内" :value="2"></el-option>
                            <el-option label="素材" :value="3"></el-option>
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column label="处理方式">
                    <template slot-scope="scope">
                        <el-select v-model="scope.row.repeatMethod" placeholder="请选择处理方式">
                            <el-option label="不处理" :value="0"></el-option>
                            <el-option label="不允许重复" :value="1"></el-option>
                            <el-option label="推迟发送" :value="2"></el-option>
                            <el-option label="丢弃" :value="3"></el-option>
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column label="回复后间隔天数">
                    <template slot-scope="scope">
                        <el-input-number v-model="scope.row.repeatDays" :min="0"
                                         v-if="scope.row.repeatMethod==2||scope.row.repeatMethod==3"></el-input-number>
                    </template>
                </el-table-column>
                <el-table-column label="未回复间隔天数">
                    <template slot-scope="scope">
                        <el-input-number v-model="scope.row.noRepeatDays" :min="0"
                                         v-if="scope.row.repeatMethod==2||scope.row.repeatMethod==3"></el-input-number>
                    </template>
                </el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogGroupRuleVisible = false">取 消</el-button>
              <el-button type="primary" @click="submitGroupRule">确 定</el-button>
          </span>
        </el-dialog>
        <el-dialog title="执行追踪" :visible.sync="dialogTraceVisible" width="60%">
            <div style="width: 100%;">
                素材：{{form.materialName}}

                <el-button type="danger" size="mini" @click="addTrace()">+加行</el-button>
            </div>
            <el-table
                    size="mini"
                    :data="traceList"
                    border
                    style="width: 100%;text-align: center">
                <el-table-column type="index" label="序号"></el-table-column>
                <el-table-column
                        label="追踪类型">
                    <template slot-scope="scope">
                        <el-select v-model="scope.row.traceType" placeholder="请选择追踪类型">
                            <el-option label="无回复" :value="0"></el-option>
                            <el-option label="有回复" :value="1"></el-option>
                            <el-option label="发链接后有回复" :value="2"></el-option>
                            <el-option label="点开链接" :value="3"></el-option>
                            <el-option label="有成交" :value="4"></el-option>
                        </el-select>
                    </template>
                </el-table-column>

                <el-table-column label="回复内容">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.content" v-if="scope.row.traceType==1||scope.row.traceType==2"
                                  placeholder="请输入回复内容"></el-input>
                    </template>
                </el-table-column>
                <el-table-column label="标记为">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.tag" placeholder="请输入标记代码"></el-input>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" width="140" label="操作">
                    <template slot-scope="scope">
                        <el-button size="mini" @click="deleteTrace(scope.row)" type="text">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogTraceVisible = false">取 消</el-button>
              <el-button type="primary" @click="submitTrace">保 存</el-button>
          </span>
        </el-dialog>
        <exampleDialog ref="exampledialog"></exampleDialog>
        <el-drawer :visible.sync="showExampleDialog" title="素材管理" size="75%">

            <materialDetail ref="materialDetail" @initList="initList"></materialDetail>
        </el-drawer>
    </defaultSec>
</template>

<script>
    import defaultSec from '@c/defaultSection'
    import tablePagination from '@c/tablePagination'
    import exampleDialog from "@c/scrm/exampleDialog";
    import materialDetail from "@c/scrm/materialDetail";
    import miniProgram from "@c/scrm/publicComponent/miniProgram";
    let apiDone = null
    export default {
        data() {
            return {
                total: 0,
                loading: false,
                loadingNum: false,
                tableHeight: 500,
                formParam: {
                    pageSize: 50,
                    currentPage: 1,
                    materialCode: '',
                    materialName: '',
                    materialType: 'text',
                    pushText:'',
                    groupId: '',
                    status: 1,
                    firstSnedTime: null,
                    date: null,
                },
                showNum: false,

                yuGuDate: this.dateAddDays(new Date(), 1),
                pickerOptions: {
                    // disabledDate(time) {
                    //     return time.getTime() < Date.now() - 8.64e6;//如果没有后面的-8.64e6就是不可以选择今天的
                    // }
                },
                dateList: [],
                materialTypeList: {
                    text: '文本',
                    image: '图片',
                    mini: '小程序',
                    news: '图文',
                },
                dialogSenceVisible: false,
                senceGroupNumForm: {
                    groupId: '',
                    groupName: '',
                    day: 1,
                    num: 0,
                    sceneList: []
                },
                tableList: [],
                materialOption: [],

                pushTypeList: [{id: 1, name: '手动推送'}, {id: 2, name: '自动推送'}],
                dialogVisible: false,
                form: {
                    id: '',
                    materialCode: '',
                    materialName: '',
                    materialType: '',
                    status: 1,
                    parentId: '',
                    materialClass: '',
                    pushText: '',
                    keyword: '',
                    url: '',
                    chanId: '',
                    seq: 0,//排序
                    pushType: 1,
                    groupId: '',//加入目录
                    isTransfer: 0,
                    isWorkTransfer: 1,
                },
                blurIndex: null,//记录光标位置
                blurIndex1: null,
                tabsTemplateList: [
                    {
                        name: '昵称参数',
                        template: '${routeCustomer.nick_name}',
                        type: 'success'
                    }, {
                        name: '手机号参数',
                        template: '${routeCustomer.phone}',
                        type: 'success'
                    }, {
                        name: '用户等级参数',
                        template: '${routeCustomer.user_level}',
                        type: 'success'
                    }, {
                        name: '插入小程序链接参数',
                        template: '<a data-miniprogram-appid="${weixinMini.appid}" data-miniprogram-path="小程序链接" >显示文本</a>',
                        type: 'success'
                    }, {
                        name: '插入H5链接参数',
                        template: '<a href="链接地址 支持小程序链接和H5链接">显示文本</a>',
                        type: 'success'
                    }, {
                        name: '寄售订单顺序号',
                        template: '${order.douyinOrderNum}',
                        type: 'success'
                    }, {
                        name: '预付卡批次名',
                        template: '${prepayCard.title}',
                        type: 'success'
                    }, {
                        name: '子单号',
                        template: '${routeCustomer.order_no}',
                        type: 'success'
                    }


                ],
                //目录参数
                tripState: [
                    {name: '全部', value: -1, point: '#55a532'},
                    {name: '已发布', value: 1, point: '#2dacd1'},
                    {name: '待发布', value: 2, point: '#e85656'},
                    {name: '驳回', value: 3, point: '#e9b222'},
                    {name: '不可用', value: 0, point: '#e85656'},
                ],
                activeTab: '1',
                treeData: [],
                sceneRoute: 3,//1,群组；2：旅程 ;3:素材
                groupList: [],
                filterGroupName: '',
                selGroup: {},
                selRoute: {},
                dialogGroupVisible: false,
                dialogGroupRuleVisible: false,
                groupRuleList: [],
                // miniTemplate:'<a data-miniprogram-appid="${weixinMini.appid}" data-miniprogram-path="小程序链接" >显示文本</a>',
                // h5Template:'<a href="链接地址 支持小程序链接和H5链接">显示文本</a>'
                traceList: [],
                dialogTraceVisible: false,
                destroyState:false,
                showExampleDialog:false

            }
        },
        components: {
            defaultSec, tablePagination,miniProgram, exampleDialog, materialDetail
        },
        mounted(){
            this.getDateList()
            this.initMaterialOption()
            this.initList()
            this.initGroupList()
            //表格高度设置  -后面数字根据页面调整
            window.onresize = () => {
                console.log(document.querySelector('.default-body').offsetHeight)
                this.tableHeight = document.querySelector('.default-body').offsetHeight - 100
            }
        },
        watch: {
            filterGroupName(val) {
                console.log(val)
                this.$refs.tree.filter(val);
            },
        },
        //页面销毁之前终端数据循环
        beforeDestroy() {
            clearTimeout(apiDone)
            this.destroyState = true
        },
        methods: {
            dateAddDays(dateStr, dayCount) {
                var tempDate = dateStr;//new Date(dateStr.replace(/-/g,"/"));//把日期字符串转换成日期格式
                var resultDate = new Date((tempDate / 1000 + (86400 * dayCount)) * 1000);//增加n天后的日期
                var resultDateStr = resultDate.getFullYear() + "-" + (resultDate.getMonth() + 1) + "-" + (resultDate.getDate());//将日期转化为字符串格式
                return resultDateStr;
            },
            goRoute(id, routeName){
                return `${window.location.origin}/routeTripDetail/${id}/${routeName}`
            },
            goSence(id){
                //this.$router.push({path: '/sceneDetail/:id', name: 'sceneDetail', params: {id: id}})
                return `${window.location.origin}/sceneDetail/${id}`
            },
            goGetNum(sceneList){
                if (sceneList == null || sceneList.length == 0) {
                    this.$message({
                        type: 'warning',
                        message: '没有对应的群组!'
                    });
                    return;
                }
                this.dialogSenceVisible = true
                this.senceGroupNumForm.sceneList = sceneList;
                this.senceGroupNumForm.groupId = sceneList[0].id;
                this.showNum = false
                this.senceGroupNumForm.num = 0
                //this.senceGroupNumForm.groupName=senceName
            },
            getGroupNum(){
                this.loadingNum = true
                this.senceGroupNumForm.day = this.dateDiff(this.yuGuDate);
                this.$apiGet('lechun-cms/scene/getUserCountByDay', this.senceGroupNumForm).then(res => {
                    this.loadingNum = false
                    this.showNum = true
                    this.senceGroupNumForm.num = res
                    //this.dialogSenceVisible=false
                })
            },
            //指定时间大于当前时间为正,指定时间小于当前时间为负
            dateDiff(targetDate) {
                let date1 = new Date(targetDate);
                let date2 = new Date();
                date1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
                date2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
                const diff = date1.getTime() - date2.getTime(); //目标时间减去当前时间
                const diffDate = diff / (24 * 60 * 60 * 1000);
                return diffDate;
            },

            //const diffDate = Math.abs(diff / (24 * 60 * 60 * 1000));//取正数
            initList() {
                this.formParam.currentPage = 1
                this.tableList = []
                this.loading = true
                this.showExampleDialog = false

                // document.querySelector('.material-table .el-table__body-wrapper').scrollTop = 0
                if (this.formParam.date != null && this.formParam.date != undefined) {
                    this.formParam.firstSnedTime = this.formParam.date[0] + "," + this.formParam.date[1];
                }
                clearTimeout(apiDone)
                this.asyncInitTableData()
            },

            asyncInitTableData(){
                this.$apiGet('lechun-cms/scrmMaterial/materialPageList', this.formParam).then(res => {
                    this.tableList = this.tableList.concat(res.list)
                    this.total = res.total
                    this.loading = false
                    this.tableHeight = document.querySelector('.default-body').offsetHeight - 100

                    console.log(this.destroyState,this.tableList.length,this.total)
                    if(!this.destroyState&&this.tableList.length<this.total){
                        apiDone = setTimeout(()=>{
                            this.formParam.currentPage++
                            this.asyncInitTableData()
                        },100)
                    }
                })
            },
            //初始化目录列表
            getDateList(){
                this.$apiGet('lechun-cms/scene/getDateList', {}).then(res => {
                    console.log(res)
                    this.dateList = res
                })
            },
            //初始化目录列表
            initGroupList(){
                this.$apiGet('lechun-cms/sceneRouteGroup/getGroupList', {
                    currentPage: 1,
                    pageSize: 10000,
                    sceneRoute: this.sceneRoute
                }).then(res => {
                    console.log(res)
                    this.treeData = res.list
                })
            },
            //筛选素材状态
            selectTripState(item){
                this.formParam.status = item.value
                this.initList()
            },
            //目录选中
            tripGroupSel(node, data){
                this.formParam.groupId = data.id
                this.initList()
            },
            //目录筛选
            filterGroupNode(value, data){
                if (!value) return true;
                return data.groupName.indexOf(value) !== -1
            },
            //目录操作
            handleGroupCommand(command, data){
                if (command == 'rule') {
                    this.addRuleGroupItem(data)
                }
                //目录编辑
                if (command == 'edit') {
                    this.addEditGroupItem(data)
                }
                if (command == 'del') {
                    this.$confirm('此操作将删除该目录, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.$apiGet('lechun-cms/sceneRouteGroup/delGroup', {id: data.id}).then(res => {
                            console.log(res)
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                        })
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消删除'
                        });
                    });
                }
            },
            //目录规则
            addRuleGroupItem(item){
                this.dialogGroupRuleVisible = true
                if (item) {
                    this.selGroup = {
                        id: item.id,
                        groupName: item.groupName,
                        type: item.type || 1,
                        sceneRoute: this.sceneRoute,
                        seq: item.seq,
                        isTransfer: item.isTransfer,
                        isWorkTransfer: item.isWorkTransfer,
                    }
                } else {
                    this.selGroup = {
                        id: '',
                        groupName: '',
                        type: 1,
                        sceneRoute: this.sceneRoute,
                        seq: 0,
                        isTransfer: '',
                        isWorkTransfer: ''
                    }
                }
                this.$apiGet('lechun-cms/sceneRouteGroup/getGroupRuleList', {groupId: item.id}).then(res => {
                    console.log(res)
                    this.groupRuleList = res;
                })
            },
            // 添加编辑目录
            addEditGroupItem(item){
                this.dialogGroupVisible = true
                if (item) {
                    this.selGroup = {
                        id: item.id,
                        groupName: item.groupName,
                        type: item.type || 1,
                        sceneRoute: this.sceneRoute,
                        seq: item.seq,
                        isTransfer: item.isTransfer,
                        isWorkTransfer: item.isWorkTransfer,
                    }
                } else {
                    this.selGroup = {
                        id: '',
                        groupName: '',
                        type: 1,
                        sceneRoute: this.sceneRoute,
                        seq: 0,
                        isTransfer: '',
                        isWorkTransfer: ''
                    }
                }
            },
            //保存目录名称
            submitGroupItem(){
                this.$apiGet('lechun-cms/sceneRouteGroup/saveGroup', {...this.selGroup}).then(res => {
                    this.$message({
                        type: 'success',
                        message: '保存成功'
                    });
                    this.dialogGroupVisible = false
                    this.initGroupList()
                })
            },
            //保存目录规则
            submitGroupRule(){
                this.$apiPost('lechun-cms/sceneRouteGroup/saveGroupRuleList', this.groupRuleList).then(res => {
                    this.$message({
                        type: 'success',
                        message: '保存成功'
                    });
                    this.dialogGroupRuleVisible = false
                    //this.initGroupList()
                })
            },
            handlePage(page){
                this.formParam.currentPage = page;
                this.initList()
            },
            // 获取光标所在位置的index
            blurEvent(e) {
                this.blurIndex = e.srcElement.selectionStart;
                this.urlChange();
                console.log(e)
                console.log(e.srcElement)
                console.log(e.srcElement.selectionStart) //光标所在的位置
            },
            // 获取光标所在位置的index
            blurEvent1(e) {
                this.blurIndex1 = e.srcElement.selectionStart;
                this.urlChange();
                console.log(e)
                console.log(e.srcElement)
                console.log(e.srcElement.selectionStart) //光标所在的位置
            },
            // 插入内容的方法
            insertContentHandle(text, type) {
                let index = type == 0 ? this.blurIndex : this.blurIndex1
                let str = type == 0 ? this.form.pushText : this.form.keyword
                if (text.indexOf("${weixinMini.appid}") >= 0) {
                    this.$apiGet('lechun-cms/weiXinBaseManage/getWeiXinBase', {"weixinBaseId": 10}).then(res => {
                        var appid = res.weixinBase.appid;
                        text = text.replace("${weixinMini.appid}", appid)
                        if (type == 0) {
                            this.form.pushText = str.slice(0, index) + text + str.slice(index);
                        } else {
                            this.form.keyword = str.slice(0, index) + text + str.slice(index);
                        }
                    })
                } else {
                    if (type == 0) {
                        this.form.pushText = str.slice(0, index) + text + str.slice(index);
                    } else {
                        this.form.keyword = str.slice(0, index) + text + str.slice(index);
                    }
                }
            },
            showDetail(row){
                console.log(123,row,this.$refs.materialDetail)
                this.showExampleDialog = true
                this.$nextTick(()=>{
                    if (row) {
                        this.$refs.materialDetail.getSingle(row.id, 0)
                    } else {
                        this.$refs.materialDetail.getSingle(null, 0)
                    }
                })

            },
            saveMaterialCode(row){
                console.log(row);
                if (row.materialCode == '') {
                    this.$message({
                        type: 'error',
                        message: '请填写素材编码'
                    });
                    return;
                }
                this.$apiGet('lechun-cms/scrmMaterial/saveMaterialCode', row).then(res => {
                    //console.log(res)
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    });

                })
            },
            saveMaterial(){
                if (this.form.materialCode == '') {
                    this.$message({
                        type: 'error',
                        message: '请填写素材编码'
                    });
                    return;
                }
                if (this.form.materialType == '') {
                    this.$message({
                        type: 'error',
                        message: '请选择素材类型'
                    });
                    return;
                }
                if (this.form.materialClass == '') {
                    this.$message({
                        type: 'error',
                        message: '请选择素材分类'
                    });
                    return;
                }
                var fun = '';
                if (this.form.materialType == 'text') {
                    fun = 'saveTextMaterial';
                    if (this.form.pushText == '') {
                        this.$message({
                            type: 'error',
                            message: '推送文本必填'
                        });
                        return;
                    }
                    // if(this.form.keyword==''){
                    //   this.$message({
                    //     type: 'error',
                    //     message: '关键字必填'
                    //   });
                    //   return;
                    // }
                } else {
                    this.$message({
                        type: 'error',
                        message: '目前只支持文本类型的素材保存'
                    });
                    return;
                }
//            this.$apiPost('lechun-csms/scrmMaterial/'+fun,this.form).then(res=>{
//                console.log("res---",res)
//                this.$message({
//                    type: 'success',
//                    message: '更改成功'
//                });
//                this.dialogVisible=false
//                this.initList()
//            })
//            this.$apiGet('lechun-cms/scrmMaterial/'+fun,this.form).then(res => {
//                console.log("res---",res)
//                this.$message({
//                    type: 'success',
//                    message: '更改成功'
//                });
//                this.dialogVisible=false
//                this.initList()
//            })
                this.$apiPost('lechun-cms/scrmMaterial/' + fun, this.form).then(res => {
                    console.log(res)
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    });
                    this.dialogVisible = false
                    this.initList()
                })
            },
            initMaterialOption(){
                this.$apiGet('lechun-cms/scrmMaterial/getOptionMaterialList', {'materialClass': 1}).then(res => {
                    this.materialOption = res;
                })
            },
            changeStatus(row){
                this.$apiGet('lechun-cms/scrmMaterial/changeStatus', {
                    'materialId': row.id,
                    'status': (row.status == 1 ? 0 : 1)
                }).then(res => {
                    console.log("res---", res)
                    this.$message({
                        type: 'success',
                        message: '更改成功'
                    });
                    this.initList()
                })
            },
            deleteMaterial(row){
                this.$confirm('确定删除选择的素材吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$apiGet('lechun-cms/scrmMaterial/deleteMaterial', {id: row.id}).then(res => {
                        this.$message({
                            showClose: true,
                            message: '成功',
                            type: 'success'
                        });
                        this.initList();
                    });
                }).catch(() => {

                });
            },
            urlChange(){
                this.$apiGet('lechun-cms/scrmMaterial/getHrefLongUrlChanId', {pushText: encodeURIComponent(this.form.pushText)}).then(res => {

                    this.form.chanId = res;
                });
            },
            addTrace(){
                var trace = {
                    "id": null,
                    "materialId": this.form.id,
                    "traceType": null,
                    "content": "",
                    "tag": "",
                    uid: new Date()
                };
                this.traceList.push(trace);
            },
            showTrace(row){
                this.form = row;
                this.dialogTraceVisible = true;
                this.getTraceList();
            },
            getTraceList(){
                var trace = {"id": null, "materialId": this.form.id, "traceType": null, "content": "", "tag": ""};
                this.$apiGet('lechun-cms/scrmMaterial/getMaterialTraceList', trace).then(res => {
                    this.traceList = res;
                });
            },
            deleteTrace(row){
                if (row.id == null) {
                    this.traceList.forEach((item, index, arr) => {
                        if (item.uid === row.uid) {
                            arr.splice(index, 1);
                        }
                    });
                    //this.traceList.remove(row);
                } else {
                    this.$apiGet('lechun-cms/scrmMaterial/deleteMaterialTrace', {traceId: row.id}).then(res => {
                        this.$message({
                            showClose: true,
                            message: '成功',
                            type: 'success'
                        });
                        this.getTraceList();
                    });
                }
            },
            submitTrace(){
                this.$apiPost('lechun-cms/scrmMaterial/saveMaterialTrace', this.traceList).then(res => {
                    this.$message({
                        type: 'success',
                        message: '保存成功'
                    });
                    this.dialogTraceVisible = false
                    //this.initGroupList()
                })
            },
            /*展示成功案例*/
            showExample(e){
                this.$refs.exampledialog.getExampleList(e.materailId)
            },
            showCheckContent(m){
                this.$message({
                    type: 'info',
                    message: m
                });
            },
            sortReplyPercent(a, b){
                return a.replyRateValue - b.replyRateValue;
            },
            sortConvertPercent(a, b){
                return a.sevenRateValue - b.sevenRateValue;
            }
        }
    }
</script>

<style lang="scss" rel="stylesheet/scss">

    .material-list {
        display: flex;
        height: 100%;
        .popper-content {
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, .4)
        }
        .route-left {
            width: 160px;
            border-radius: 5px 0 0 5px;
            height: calc(100% - 20px);
            padding: 10px;
            background: #f5f5f5;
            font-size: 12px;
            box-shadow: 1px 0 6px 0 rgba(0, 0, 0, .26);
            position: relative;
            z-index: 9;
        }
        .route-left-item {
            cursor: pointer;
        }
        .route-left-item-on {
            background: rgba(32, 158, 145, .2);
        }
        .route-right {
            flex: 1;
            height: calc(100% - 20px);
            background: #ffffff;
            border-radius: 0 5px 5px 0;
            padding: 10px 10px 10px 15px;
        }
        .copy-text {
            white-space: pre-line;
            margin: 0;
            line-height: 18px;
            background: #f2f2f2;
            border: 1px solid #e8e8e8;
            border-radius: 5px;
            padding: 6px;
            max-height: 127px;
            overflow-y: scroll;
        }
        .el-tabs__item {
            font-size: 12px;
            padding: 0 9px;
        }
        .el-tree {
            background: transparent;
            font-size: 12px;
        }
        .custom-tree-node {
            flex: 1;
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            color: #606060;
            .tree-name {
                position: relative;
                top: 4px;
                display: inline-block;
                width: 130px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin: 0;
                cursor: pointer;
            }
            .drop-down {
                font-size: 12px;
                color: #2dacd1;
                line-height: 24px;
            }
            .icon-edit {
                transform: rotate(90deg);
            }
            .icon-green {
                color: #55a532;
            }
            .icon-red {
                color: #e50011;
            }
        }
        .el-tree-node__content > .el-tree-node__expand-icon {
            padding: 1px;
            font-size: 0;
        }
        .is-Current-node {
            background: rgba(32, 158, 145, 0.2);
        }
        .el-dialog {
            .el-tree {
                font-size: 14px;
            }
            .tree-name {
                width: 100%;
                font-size: 14px;
            }
        }
        .el-dialog__body {
            padding-top: 20px;
        }
    }

</style>


